import { useEffect, useState } from "react";

const Root = () => {
    const [authData, setAuthData] = useState<any>();
    const toggleClass = () => {document.querySelector(".sidebar-min").classList.toggle("sidebar-max")};

    useEffect(() => {
        let sub;
        const loadAuthModule = async () => {
            const auth = await System.import('@deloitte-dsoe/portal-auth');
            sub = auth.context.subscribe(authData => setAuthData(authData));
        }
        loadAuthModule();
        return () => {
            sub?.unsubscribe();
        };
    });
    if(authData?.isAuthenticated && authData?.sessionToken)
        return (
            <div className="sidebar-min nav-vh-100">
                <div className="bg-white nav-vh-100">
                    <ul className="secondary-nav list-unstyled mb-0">
                        <li>
                            <a href={undefined} className="p-3 menu-toggle" title="menu" onClick={toggleClass}>
                                <span className="menu-icon menu-open">
                                    <img src="/assets/images/hamburger.svg"/>
                                </span>
                                <span className="menu-icon menu-close">
                                    <img src="/assets/images/close.svg"/>
                                </span>
                                <span className="menu-text text-secondary">&nbsp; Close</span>
                            </a>
                        </li>
                        <li>
                            <a href="/dsoeportal" className="p-3" title="My Applications">
                                <span className="menu-icon d-inline-block">
                                    <img src="/assets/images/my-applications.svg" height="20"/>
                                </span>
                                <span className="menu-text" title="My Applications">&nbsp; My Applications</span>
                            </a>
                        </li>
                        <li>
                            <a href={undefined} className="p-3 disabled" title="Application Dashboard - Coming soon">
                                <span className="menu-icon d-inline-block">
                                    <img src="/assets/images/application-dashboard-grey.svg" height="20"/>
                                </span>
                                <span className="menu-text" title="Application Dashboard - Coming soon">&nbsp; Application Dashboard</span>
                            </a>
                        </li>
                        <li>
                            <a href={undefined} className="p-3 disabled" title="Infrastructure Dashboard - Coming soon">
                                <span className="menu-icon d-inline-block">
                                    <img src="/assets/images/infrastructure-grey.svg" height="20"/>
                                </span>
                                <span className="menu-text" title="Infrastructure Dashboard - Coming soon">&nbsp; Infrastructure Dashboard</span>
                            </a>
                        </li>
                        <li>
                            <a href={undefined} className="p-3 disabled" title="Compliance Dashboard - Coming soon">
                                <span className="menu-icon d-inline-block">
                                    <img src="/assets/images/compliance-grey.svg" height="20"/>
                                </span>
                                <span className="menu-text" title="Compliance Dashboard - Coming soon">&nbsp; Compliance Dashboard</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        );
}

export default Root;